import { LoadingModalState } from "@components/LoadingModal";

type PartialState = Omit<Partial<LoadingModalState>, "id">;
type MessageRequired = SomeRequired<PartialState, "message">;

export const Final = (opt: MessageRequired): LoadingModalState => {
  const { extra = [], ...rest } = opt;
  return {
    id: "final-state",
    final: true,
    title: "Done",
    subtitle: "You can safely close this dialog",
    extra: [
      {
        title: "Close",
        variant: "disabled",
        onClick: (ref) => {
          ref.current?.reset();
        },
      },
      ...extra,
    ],
    ...rest,
  };
};

export const Fail = (opt: MessageRequired): LoadingModalState =>
  Final({
    title: "There was a problem",
    subtitle: "If the error persists contact support",
    ...opt,
  });

export const SignatureRejected = (opt?: PartialState): LoadingModalState =>
  Fail({
    message: "Signature rejected",
    subtitle: "Your signature is required",
    ...opt,
  });

export const SignatureRequired = (opt?: PartialState): LoadingModalState => ({
  id: "signature-required",
  title: "Signature Required",
  message: "Waiting on wallet confirmation",
  subtitle: "Your signature is required to continue",
  ...opt,
});

export const SignatureSuccess = (opt?: PartialState): LoadingModalState => ({
  id: "signature-success",
  title: "Executing...",
  message: "Signature received",
  subtitle: "Submitting your transaction to the blockchain...",
  ...opt,
});

export const TxMined = (opt?: PartialState): LoadingModalState => {
  return {
    id: "tx-mined",
    message: "Transaction sucessful",
    subtitle: "Your transaction has been included in the blockchain",
    ...opt,
  };
};

export const AsyncLoad = (opt: MessageRequired): LoadingModalState => ({
  id: "async",
  subtitle: "This might take a few moments...",
  ...opt,
});

type ConfirmationOpts = Omit<MessageRequired, "extra"> & {
  confirmation: NonNullable<LoadingModalState["extra"]>[number];
};
export const Confirm = (opt: ConfirmationOpts): LoadingModalState => {
  const { confirmation, ...rest } = opt;
  return Final({
    title: "Confirm",
    extra: [confirmation],
    ...rest,
  });
};

export const SubscribeFail = (opt: MessageRequired): LoadingModalState =>
  Final({
    title: "Invalid e-mail",
    subtitle: "Please insert a valid e-mail address",
    ...opt,
  });

export const SubscribeSuccess = (opt?: PartialState): LoadingModalState => ({
  id: "subscribe-success",
  title: "Subscribed",
  message: "You have successfully subscribed 1",
  subtitle: "You have successfully subscribed 2",
  ...opt,
});

export const InsufficientBalanceFail = (
  opt?: PartialState
): LoadingModalState =>
  Final({
    title: "There was a problem",
    message: "Insufficient balance",
    ...opt,
  });
