import { ExtraOptions, LoadingModalHandle } from "@components/LoadingModal.jsx";
import { User } from "database";
import { addressTransformer } from "common/validators/values";
import { AssetTransfersResult, fromHex } from "alchemy-sdk";
import { SUPPORTED_NETWORKS } from "blockchain/utils";
import { BigNumber, BigNumberish, constants } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import md5 from "md5";
import getConfig from "next/config";
import { z } from "zod";
import { env } from "../env/client.mjs";
import { Fail, SignatureRejected } from "./loadingStates";
import { NextApiRequest } from "next";

export const simplifyAddress = (address: string) =>
  `0x${address.slice(2, 5)}...${address.slice(-3)}`;

export const formatValue = (value: number) => {
  if (value > 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value > 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value;
};

export const parseAddress = (str: string | null = constants.AddressZero) => {
  const address = addressTransformer.safeParse(str);
  if (address.success) {
    return address.data;
  }
  return constants.AddressZero;
};

export const convertWeiStringToEther = (
  wei: string,
  unit: string | number = "ether"
) => formatUnits(BigNumber.from(wei), unit);

export const convertEtherWeiStringToString = (
  ether: string,
  unit: string | number = "ether"
) => parseUnits(ether, unit).toString();

export const network: SUPPORTED_NETWORKS =
  env.NEXT_PUBLIC_BLOCKCHAIN_ENV === "test" ? "hardhat" : "maticmum";

export const generateArrayFromNumber = (number: number) => {
  return Array.from(Array(number).keys());
};

type GratavarDefaults =
  | "mp"
  | "identicon"
  | "monsterid"
  | "wavatar"
  | "retro"
  | "robohash"
  | "blank";

export const defaultUserImage = (
  user: Pick<User, "address">,
  defaults: GratavarDefaults = "retro"
) => {
  return `https://www.gravatar.com/avatar/${md5(
    user.address
  )}.jpg?d=${defaults}`;
};

export const filterTransfersByTokenId = <T extends { tokenId: BigNumberish }>(
  transfers: T[],
  tokenId: string
) => {
  return transfers.filter((txn) => {
    if (!txn.tokenId) {
      return false;
    }

    return fromHex(txn.tokenId.toString()) === Number(tokenId);
  });
};
export const retrySignature = (
  error: Error,
  loader: LoadingModalHandle | null,
  onClick: ExtraOptions["onClick"]
) => {
  if (error.message.toLowerCase().includes("signature")) {
    loader?.push(
      SignatureRejected({
        extra: [
          {
            onClick,
            title: "Sign",
          },
        ],
      })
    );
  } else {
    loader?.push(
      Fail({
        message: error.message,
        extra: [
          {
            onClick,
            title: "Retry",
          },
        ],
      })
    );
  }
};

export const isImageUrlInConfig = (str: string) => {
  const { publicRuntimeConfig } = getConfig();
  const domains = z.array(z.string()).parse(publicRuntimeConfig.domains);
  return domains.find((domain) => str.includes(domain));
};

export const parseDomainFromReq = (referer: string) => {
  const [, , domain] = referer.split("/").filter(Boolean) || [];
  return z.string().min(1).safeParse(domain);
};

export const formatEventDate = (date: Date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    // year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};
