import { addressTransformer } from "common/validators/values";
import { Log, Provider } from "@ethersproject/providers";
import { constants, Contract, Signer } from "ethers";
import { EventFragment } from "ethers/lib/utils";
import z from "zod";
import {
  CollectionFactoryABI,
  ERC20PermitableABI,
  MetaTransactableABI,
  MinimalForwarderABI,
  PlatformTokenABI,
  Supa1155ABI,
  SupaCoinABI,
  SupaMarketABI,
  SupaNFTABI,
  TwoStepSwapABI,
  UniswapFactoryABI,
  UniswapPairABI,
  UniswapRouterABI,
  ERC165ABI,
  SupaEventABI,
  BeaconProxyFactoryABI,
} from "./abis";
import d from "./deploy.json";
import {
  CollectionFactory,
  ERC20Permit,
  MetaTransactable,
  MinimalForwarder,
  PlatformToken,
  Supa1155,
  SupaCoin,
  SupaNFT,
  TwoStepSwap,
  UniswapV2Factory,
  UniswapV2Pair,
  UniswapV2Router02,
  ERC165,
  SupaMarket,
  SupaEvent,
  BeaconProxyFactory,
} from "./typechain-types";

const deploy = d as Record<string, Record<string, string>>;

export type SUPPORTED_NETWORKS = keyof typeof d;
export const networkValidator = z.enum(
  // Not ideal but works
  Object.keys(d) as [SUPPORTED_NETWORKS]
);

const networkSetupValidator = z
  .object({
    USDC: addressTransformer,
    WrappedMatic: addressTransformer,
    SupaMarket: addressTransformer,
    SupaNFT: addressTransformer,
    Supa1155: addressTransformer,
    CollectionFactory: addressTransformer,
    SpaceChips: addressTransformer,
    SwapFactory: addressTransformer,
    SwapRouter: addressTransformer,
    TwoStepSwap: addressTransformer,
    BeaconProxyFactory: addressTransformer,
    SupaEvent: addressTransformer,
  })
  .strict();

export const getContracts = (
  network: SUPPORTED_NETWORKS = "maticmum",
  signerOrProvider?: Signer | Provider
) => {
  const valid = networkValidator.parse(network);
  const contractAddresses = networkSetupValidator.parse(deploy[valid]);
  if (!contractAddresses)
    throw new Error(`No contracts found for network ${network}`);
  return {
    CollectionFactory: new Contract(
      contractAddresses.CollectionFactory ?? "",
      CollectionFactoryABI,
      signerOrProvider
    ) as CollectionFactory,
    ERC20Permitable: new Contract(
      constants.AddressZero,
      ERC20PermitableABI,
      signerOrProvider
    ) as ERC20Permit,
    SupaMarket: new Contract(
      contractAddresses.SupaMarket ?? "",
      SupaMarketABI,
      signerOrProvider
    ) as SupaMarket,
    SupaNFT: new Contract(
      contractAddresses.SupaNFT ?? "",
      SupaNFTABI,
      signerOrProvider
    ) as SupaNFT,
    Supa1155: new Contract(
      contractAddresses.Supa1155 ?? "",
      Supa1155ABI,
      signerOrProvider
    ) as Supa1155,
    MinimalForwarder: new Contract(
      constants.AddressZero,
      MinimalForwarderABI,
      signerOrProvider
    ) as MinimalForwarder,
    SpaceChips: new Contract(
      contractAddresses.SpaceChips ?? "",
      PlatformTokenABI,
      signerOrProvider
    ) as PlatformToken,
    MetaTransactable: new Contract(
      constants.AddressZero,
      MetaTransactableABI,
      signerOrProvider
    ) as MetaTransactable,
    SwapRouter: new Contract(
      contractAddresses.SwapRouter,
      UniswapRouterABI,
      signerOrProvider
    ) as UniswapV2Router02,
    SwapFactory: new Contract(
      contractAddresses.SwapFactory,
      UniswapFactoryABI,
      signerOrProvider
    ) as UniswapV2Factory,
    SwapPair: new Contract(
      constants.AddressZero,
      UniswapPairABI,
      signerOrProvider
    ) as UniswapV2Pair,
    TwoStepSwap: new Contract(
      contractAddresses.TwoStepSwap,
      TwoStepSwapABI,
      signerOrProvider
    ) as TwoStepSwap,
    SupaCoin: new Contract(
      constants.AddressZero,
      SupaCoinABI,
      signerOrProvider
    ) as SupaCoin,
    SupaEvent: new Contract(
      contractAddresses.SupaEvent ?? "",
      SupaEventABI,
      signerOrProvider
    ) as SupaEvent,
    BeaconProxyFactory: new Contract(
      contractAddresses.BeaconProxyFactory ?? "",
      BeaconProxyFactoryABI,
      signerOrProvider
    ) as BeaconProxyFactory,
  };
};

export const makeStruct = <T extends Record<string, unknown>>(
  output: Array<any>,
  fragment: EventFragment
): T => {
  const orderFragment = fragment.inputs[2]?.components;
  if (!orderFragment) throw new Error("Invalid Input");
  return orderFragment.reduce((prev, curr, i) => {
    prev[curr.name] = output[i];
    return prev;
  }, {} as any) as T;
};

export const filter = <T extends unknown>(
  array: Array<T>
): Array<NonNullable<T>> => {
  return array.filter(Boolean) as NonNullable<T>[];
};

export const EIP712Types = {
  MetaTransaction: [
    { name: "calling", type: "string" },
    { name: "withArgs", type: "string[]" },
    { name: "data", type: "bytes" },
    { name: "nonce", type: "uint256" },
    { name: "from", type: "address" },
    { name: "to", type: "address" },
  ],
  EIP712Domain: [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
  ],
  Permit: [
    { name: "owner", type: "address" },
    { name: "spender", type: "address" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ],
  Order: [
    { name: "collectionAddress", type: "address" },
    { name: "itemId", type: "uint256" },
    { name: "itemAmount", type: "uint256" },
    { name: "tokenAddress", type: "address" },
    { name: "tokenAmount", type: "uint256" },
    { name: "makerAddress", type: "address" },
    { name: "orderType", type: "bytes32" },
    { name: "nonce", type: "uint256" },
    { name: "expiration", type: "uint256" },
  ],
};

export const makeEIP712Domain = (
  name: string,
  version: string,
  chainId: number,
  verifyingContract: string
) => ({
  name,
  version,
  chainId,
  verifyingContract,
});

export const deploys = d;

// Reference contracts/interfaces/IMetaTransactable.sol
export const META_TRANSACTABLE_INTERFACE_ID = "0x2b134e93";

export const isMetaTransactable = async (contractAddress: string) => {
  const ERC165 = new Contract(contractAddress, ERC165ABI) as ERC165;
  try {
    return ERC165.supportsInterface(META_TRANSACTABLE_INTERFACE_ID);
  } catch (err) {
    const error = err as Error;
    if (error.message.includes("code=CALL_EXCEPTION")) return false;
    else throw error;
  }
};

export const getCreateProxyEvent = (logs: Log[]) => {
  for (const log of logs) {
    try {
      const event = getContracts().BeaconProxyFactory.interface.parseLog(log);
      if (event.name === "BeaconProxyDeployed") {
        return event;
      }
    } catch {
      continue;
    }
  }
};
