import { utils } from "ethers";
import { z } from "zod";

export const addressTransformer = z
  .string()
  .startsWith("0x")
  .transform((v) => utils.getAddress(v));

export const statusValidator = z.enum([
  "pending",
  "processing",
  "completed",
  "failed",
]);

export const statusScoreValidator = z.enum(["1", "2", "3", "4"]);

export const webhookValidator = z.object({
  url: z.string().url(),
});
