import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { network } from "@utils/index";
import { getContracts } from "blockchain/utils";
import * as deploy from "blockchain/deploy.json";
import { utils } from "ethers";
import { useMemo } from "react";
import { useProvider, useSigner } from "wagmi";

export const useSignMessage = (
  options?: Omit<
    UseMutationOptions<string, unknown, string, unknown>,
    "mutationFn"
  >
) => {
  const { data: signer } = useSigner();
  return useMutation(async (hash: string) => {
    if (!signer) throw new Error(`No signer`);
    return signer.signMessage(utils.arrayify(hash));
  }, options);
};

export const useGetTransaction = () => {
  const provider = useProvider();

  return useMutation((hash: string) => provider.getTransaction(hash));
};

export const useContracts = () => {
  const provider = useProvider();
  const { data: signer } = useSigner();
  const contracts = getContracts(network, signer ?? provider);
  return useMemo(
    () => ({ ...contracts, provider: signer ?? provider }),
    [signer, provider, contracts]
  );
};

export const useWaitTransaction = () => {
  const provider = useProvider();

  if (!provider) {
    throw new Error("Not Provider");
  }

  return (hash: string) => provider.waitForTransaction(hash);
};

export const useDeploys = () => {
  return deploy[network];
};

export type MutationCallbacks<TArgs, TSignPayload, TTransaction> = {
  onSign: (args: TArgs, payload: TSignPayload) => void | Promise<void>;
  // onRelay: (
  //   tx: TTransaction,
  //   args: TArgs,
  //   payload: TSignPayload
  // ) => void | Promise<void>;
};
